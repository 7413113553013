<template>
  <div>
    <v-toolbar flat>
      <v-autocomplete
        :items="names"
        label="Beschreibung"
        v-model="filter.name"
        hide-details=""
        clearable
        class="mr-4"
      />
      <v-autocomplete
        :items="categories"
        label="Kategorie"
        v-model="filter.category"
        hide-details=""
        clearable
        class="mr-4"
      />

      <v-spacer />
      <v-btn icon @click="getData"><v-icon>mdi-refresh</v-icon></v-btn>
      <template v-slot:extension>
        <v-switch
          inset
          hide-details
          label="gesperrte sichtbar"
          v-model="filter.locked"
          class="mr-4"
        ></v-switch>
        <v-switch
          inset
          hide-details
          label="nur akzeptierte"
          v-model="filter.accepted"
          class="mr-4"
        ></v-switch>

        <v-spacer />
        <v-btn color="success" text @click="selectAll"
          ><v-icon left>mdi-checkbox-multiple-marked</v-icon> sichtbare
          akzeptieren</v-btn
        >
        <v-btn color="error" text @click="unselectAll"
          ><v-icon left>mdi-checkbox-multiple-blank</v-icon> sichtbare
          abwählen</v-btn
        >
      </template>
    </v-toolbar>
    <v-card class="mt-4">
      <v-data-table
        :loading="loading"
        :headers="headers"
        :items="changesFiltered"
        :item-class="getCss"
        @click:row="(item) => toggleChange(item)"
        sort-by="id"
        ref="changes"
      >
        <template v-slot:item.accepted="{ item }">
          <v-simple-checkbox
            :value="item.accepted"
            :ripple="false"
            @click="toggleChange(item)"
          ></v-simple-checkbox>
        </template>
        <template v-slot:item.type="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                :color="getTypeColor(item.type.code)"
                >{{ getTypeIcon(item.type.code) }}</v-icon
              >
            </template>
            <span>{{ item.type.description }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.value="{ item }">
          <p class="mb-0" v-if="item.oldValue && item.oldValue != '<null>'">
            <strike>{{ item.oldValue }}</strike>
          </p>
          <p class="mb-0" v-if="item.newValue && item.newValue != '<null>'">
            {{ item.newValue }}
          </p>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "ChangesView",
  props: ["search"],
  data: () => ({
    loading: false,
    filter: {
      accepted: false,
      category: null,
      locked: false,
      name: null,
    },
    categories: [],
    names: [],
    changes: [],
    active: 0,
    query: {},
    queryString: "",

    headers: [
      { text: "", value: "accepted" },
      { text: "Beschreibung", value: "name" },
      { text: "Kategorie", value: "category.description" },
      {
        text: "Typ",
        value: "type",
        groupable: false,
        sortable: false,
      },
      {
        text: "Änderung",
        value: "popertyText",
        groupable: false,
        sortable: false,
      },
      { text: "Wert", value: "value", groupable: false, sortable: false },
    ],
  }),
  computed: {
    changesFiltered() {
      return this.changes.filter(
        (change) =>
          (this.filter.locked || !change.locked) &&
          (!this.filter.accepted || change.accepted) &&
          (!this.filter.name || this.filter.name === change.name) &&
          (!this.filter.category ||
            this.filter.category === change.category.description) &&
          (!this.search ||
            change.name.toLowerCase().includes(this.search.toLowerCase()))
      );
    },
  },
  watch: {
    filter: {
      deep: true,
      handler() {
        localStorage.setItem("changesViewFilter", JSON.stringify(this.filter));
      },
    },
  },
  methods: {
    selectAll() {
      const visible = this.$refs.changes._data.internalCurrentItems;
      visible.forEach(async (item) => {
        if (!item.accepted) {
          await this.apiPut({
            resource: "change/change",
            data: { id: item.id, accepted: true },
          });
          item.accepted = true;
        }
      });
    },
    unselectAll() {
      const visible = this.$refs.changes._data.internalCurrentItems;
      visible.forEach(async (item) => {
        if (item.accepted) {
          await this.apiPut({
            resource: "change/change",
            data: {
              id: item.id,
              accepted: false,
            },
          });
          item.accepted = false;
        }
      });
    },
    async getData() {
      this.loading = true;
      this.changes = await this.apiList({ resource: "change/change" });
      this.changes.forEach((change) => {
        if (!this.names.includes(change.name)) {
          this.names.push(change.name);
        }
        if (!this.categories.includes(change.category.description)) {
          this.categories.push(change.category.description);
        }
      });
      if (
        this.filter &&
        this.filter.name &&
        !this.names.includes(this.filter.name)
      ) {
        this.names.push(this.filter.name);
      }
      this.names.sort();
      if (
        this.filter &&
        this.filter.category &&
        !this.categories.includes(this.filter.category)
      ) {
        this.categories.push(this.filter.category);
      }
      this.categories.sort();
      this.loading = false;
    },
    getCss(item) {
      if (item.accepted) {
        return "accepted";
      }
      if (item.locked) {
        return "locked";
      }
    },
    getTypeIcon(code) {
      if (code === "remove") {
        return "mdi-minus-circle";
      }
      if (code === "add") {
        return "mdi-plus-circle";
      }
      if (code === "change") {
        return "mdi-sync-circle";
      }
    },
    getTypeColor(code) {
      if (code === "remove") {
        return "error";
      }
      if (code === "add") {
        return "success";
      }
      if (code === "change") {
        return "info";
      }
    },
    async toggleChange(change) {
      if (change.locked) {
        if (
          !change.accepted &&
          !(await this.$root.confirm({
            message:
              "Diese Änderung ist gesperrt. Soll sie trotzdem angenommen werden?",
            color: "warning",
            icon: "mdi-lock",
          }))
        ) {
          return;
        }
      }
      await this.apiPut({
        resource: "change/change",
        data: { id: change.id, accepted: !change.accepted },
      });
      change.accepted = !change.accepted;
    },
  },
  async created() {
    this.getData();
    if (localStorage.getItem("changesViewFilter")) {
      this.filter = JSON.parse(localStorage.getItem("changesViewFilter"));
    }
  },
});
</script>

<style>
tr.locked {
  background-color: lightgrey !important;
}
tr.accepted {
  background-color: #e8cc00 !important;
}
</style>
